var rotator = {
	display : function(wide){
		setTimeout(function(){
			//make sure we have somewhere to display the Rs
			var inlineRs = document.querySelectorAll('.inline-rotate');
			if (inlineRs.length < 1)
				return false; // we have nowhere to display the Rs
			// make sure we have an array of Rs
			if(__rotatableRs.length < 1)
				return false; //something went wrong with the rotatableRs var

			var validRs = [];
			for(var i = 0; i < __rotatableRs.length; i++){
				if(__rotatableRs[i].rule){
					var validRObj = {
						id : i,
						score : __rotatableRs[i].weight * Math.random()
					};
					validRs.push(validRObj);
				}
			}
			//sort Rs by weight
			validRs.sort(function(a,b){return b.score-a.score;});

			var k = 0; // how many Rs we are going to display

			// Display one R for each div of class type inline-rotate
			// If there are more divs than Rs only display as many
			// Rs as there are, leaving some divs empty.
			if (validRs.length < inlineRs.length)
				k = validRs.length;
			else
				k = inlineRs.length;

			let rLoaded = false;
			function handleImgLoad(event) {
				if (!rLoaded) {
					rLoaded = true;
					rLoadedFirst();
				}
				event.target.removeEventListener('load', handleImgLoad);
			}
			function rLoadedFirst() {
				// set on the global to use in index.html Simple metadata:
				window.sa_rLoaded = true;
			}
			function hrefCreate(rotatableR){
				let anchorElement = document.createElement('a');
				anchorElement.setAttribute('href', '/t?e=' + encodeURIComponent(rotatableR.linkTarget) +
					'&a=' + encodeURIComponent(rotatableR.eventMeta) +
					(isApp == 'iOSApp' ? '&m=ios' : ''));
				anchorElement.setAttribute('target', '_blank');
				anchorElement.setAttribute('rel', 'noopener');
				return anchorElement;
			}
			function imgCreate(imageFileName, rotatableR){
				let imgElement = document.createElement('img');
				imgElement.loading = 'lazy';
				imgElement.src = '/assets/media/rotations/' + imageFileName;
				imgElement.alt = rotatableR.altText;
				return imgElement;
			}
			document.querySelectorAll('.inline-rotate').forEach(function(element, index) {
				if (index < k) {
					var squareImageFileName = __rotatableRs[validRs[index].id].squareImageName || '';
					var wideImageFileName = __rotatableRs[validRs[index].id].wideImageName || '';

					if (element.classList.contains('inline-rotate-maplist')) {
						let wideImage = '';
						if (wideImageFileName !== '') {
							wideImage = imgCreate(wideImageFileName, __rotatableRs[validRs[index].id]);
							wideImage.addEventListener('load', handleImgLoad);
						}
						let wideImageHtml = hrefCreate(__rotatableRs[validRs[index].id]);
						wideImageHtml.classList.add('wide-rotate');
						wideImageHtml.appendChild(wideImage);

						let squareImage = imgCreate(squareImageFileName, __rotatableRs[validRs[index].id]);
						squareImage.addEventListener('load', handleImgLoad);
						let squareImageHtml = hrefCreate(__rotatableRs[validRs[index].id]);
						squareImageHtml.classList.add('square-rotate');
						squareImageHtml.appendChild(squareImage);

						element.innerHTML = '';
						element.appendChild(wideImageHtml);
						element.appendChild(squareImageHtml);
					} else {
						let imgElement = imgCreate(squareImageFileName, __rotatableRs[validRs[index].id]);
						imgElement.addEventListener('load', handleImgLoad);

						let anchorElement = hrefCreate(__rotatableRs[validRs[index].id]);
						anchorElement.appendChild(imgElement);

						element.innerHTML = '';
						element.appendChild(anchorElement);
					}
				}
			});
		}, 750);
	},
	scoreCompare : function(a,b) {
	  if (a.score < b.score)
		 return -1;
	  if (a.score > b.score)
		return 1;
	  return 0;
	},
	activeRsCount: function(){
		// make sure we have an array of Rs
		if(__rotatableRs.length < 1)
			return 0; //something went wrong with the rotatableRs var

		var validRs = [];
		for(var i = 0; i < __rotatableRs.length; i++){
			if(__rotatableRs[i].rule){
				var validRObj = {
					id : i,
					score : __rotatableRs[i].weight * Math.random()
				};
				validRs.push(validRObj);
			}
		}
		return validRs.length;
	}
};

/********* Rotator ***************************************/
/* Object Description									*/
/*															*/
/*	squareImageName :  This is the filename only of 		*/
/*						the image to use.		*/
/*						To decrease load time multiple		*/
/*						base urls are used.					*/
/*	wideImageName   :  This is the filename only of 		*/
/*						the wide image to use.	*/
/*						To decrease load time multiple		*/
/*						base urls are used.					*/
/*	altText			:  Alternative text provided in			*/
/*						the HTML img tag.					*/
/*	linkTarget		: Target that the image should		*/
/*						link to.							*/
/*	eventMeta	: Text that will be logged when 			*/
/*						clicked. This could be a			*/
/*						campaign name or a url.				*/
/*	weight			: The relative frequency that a		*/
/*						rotation is displayed compared to			*/
/*						others. The higher the 			*/
/*						value the more frequent. 			*/
/*						This is just a js int, but lets		*/
/*						be sane an keep it between 1-100	*/
/*	rule			: If true then the rotation is valid to be  	*/
/*						displayed. 							*/
/************************************************************/
var __rotatableRs = [];
var activeRsCount = 0;

fetch('https://get.geojs.io/v1/ip/country') //
	.then(response => response.text())
	.then(countryCode => {
		if(countryCode && countryCode.trim().length == 2 ) {
			const allowedCountries = ['US', 'CA', 'VI', 'PR', 'GU', 'AS', 'BS', 'BM', 'KY', 'TC', 'FM', 'MH', 'PW'];
			if (allowedCountries.includes(countryCode.trim())) fetchRotations();
		}
	})
	.catch(error => Sentry.captureException(error));

function fetchRotations(){
	// read json from file:
	fetch('/rotator.json')
	  .then(response => response.json())
	  .then(data => {
		__rotatableRs = data;
		activeRsCount = rotator.activeRsCount();
	})
	.catch(error => Sentry.captureException(error));
}
